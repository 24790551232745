import axios, { StdResponse } from "@/config/axios";
import { AxiosResponse } from "axios";
// import { CheckItem } from "@/interface/check-item";
// import { CheckItemPhoto } from "@/interface/check-item-photo";

export function getDevicesByGroupId(): Promise<AxiosResponse<any>> {
    // return axios.get(`https://jk.railshj.com/jn-gateway-pnprd/jn-bigscreen/bigscreen/video/getDevicesByGroupId?groupId=4503620affcd46c19aa9ff29552abf83`, {
    return axios.get(`https://jk.railshj.com/jn-gateway-pnprd/jn-bigscreen/bigscreen/video/getDevicesByGroupId?groupId=5c932ab5840a4328910615ace92941b0`, {
    })
}

export function getMonitoringVideo(deviceId: string): Promise<AxiosResponse<any>> {
    return axios.post(`https://jk.railshj.com/jn-gateway-pnprd/jn-bigscreen/bigscreen/video/getMonitoringVideo`, { deviceId: deviceId }, {})
}

// export function updateCheckItem(itemId: number, checkItem: CheckItem, params?: {}): Promise<StdResponse<any>> {
//     return axios.put(`/check-items/${itemId}`, checkItem, {
//         params: params
//     })
// }

// export function uploadCheckItemPhoto(itemId: number, checkItemPhoto: CheckItemPhoto, params?: {}): Promise<StdResponse<any>> {
//     return axios.post(`/check-items/${itemId}/photos`, checkItemPhoto, {
//         params: params
//     })
// }

// export function deleteCheckItemPhoto(photoId: number, params?: {}): Promise<StdResponse<any>> {
//     return axios.delete(`/check-items/photos/${photoId}`, {
//         params: params
//     })
// }