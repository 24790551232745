import { useEffect, useRef, useState } from 'react';
import './index.less'
import { useNavigate } from 'react-router-dom';
import { getDevicesByGroupId, getMonitoringVideo } from '@/api/video';
import React from 'react';
import { Player } from 'lm-player';
import { ReactComponent as NanSvg } from '@/icons/nan.svg'
import { ReactComponent as OneSvg } from '@/icons/number/one.svg'
import { ReactComponent as TwoSvg } from '@/icons/number/two.svg'
import { ReactComponent as ThreeSvg } from '@/icons/number/three.svg'
import axios from 'axios';

export const MODE = {
    MODEL: 1,
    VIDEO: 2,
    MAP: 3,
    MONIX: 4,
}

export default function MassifMonitorPanel() {

    const BackgroundImage = process.env.PUBLIC_URL + '/media/background2.png';
    const IndexDetailImage = process.env.PUBLIC_URL + '/media/index-detail.png';
    const LeftFloorImage = process.env.PUBLIC_URL + '/media/left-floor.png';
    const LeftMonitorImage = process.env.PUBLIC_URL + '/media/left-monitor.png';
    const MapImage = process.env.PUBLIC_URL + '/media/map.png';

    const navigate = useNavigate();
    const LeftRef = useRef(null);
    const [showSubBorder, setShowSubBorder] = useState(false);
    const [devices, setDevices] = useState([]);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [vedioUrl, setVedioUrl] = useState('');
    const [vedioUrlArr, setVedioUrlArr] = useState<string[]>([]);
    const [timeStr, setTimeStr] = useState('');
    const [currentMode, setCurrentMode] = useState<number>(MODE.MONIX);

    const getBackgroundImage = () => {
        switch (currentMode) {
            case 1:
                return LeftFloorImage;
            case 2:
                return null;
            case 3:
                return MapImage;
            case 4:
                return LeftMonitorImage;
            default:
                return null;
        }
    };

    const getNumSvg = (number) => {
        if (number === 0) {
            return <OneSvg />
        } else if (number === 1) {
            return <TwoSvg />
        } else if (number === 2) {
            return <ThreeSvg />
        }
        return null
    }

    useEffect(() => {
        getDevicesByGroupId().then(res1 => {
            let devices = (res1 as any).result || [];
            setDevices(devices);
            return devices;
        }).then(devices => {
            if (!devices) return;
            let deviceArr = [];
            let axiosArr = [];
            // for (let d of devices) {
            for (let i = 0; i < devices?.length; i++) {
                if(i === 0) continue;
                let d = devices[i];
                let deviceId = d.deviceId;
                if (axiosArr?.length < 3) {
                    axiosArr.push(getMonitoringVideo(deviceId));
                    deviceArr.push(d);
                } else {
                    break;
                }
            }
            setSelectedDevices(deviceArr);
            axios.all([...axiosArr]).then(res => {
                let vedioUrlArr = [];
                for (let r of res) {
                    let vedioUrl = (r as any).result.videoUrl;
                    vedioUrlArr.push(vedioUrl);
                }
                setVedioUrlArr(vedioUrlArr);
            })
        })
    }, [])

    const monitorClick = (device) => {
        let flag = selectedDevices.some(item => item.deviceId === device.deviceId);
        if (flag) {
            return;
        }
        let newSrr = [...selectedDevices];
        newSrr.unshift(device);
        newSrr.pop();
        setSelectedDevices(newSrr);

        getMonitoringVideo(device.deviceId).then(res => {
            let videoUrl = (res as any).result.videoUrl;
            let newUrls = [...vedioUrlArr];
            newUrls.unshift(videoUrl);
            newUrls.pop();
            setVedioUrlArr(newUrls);
        })
    }

    return (
        <div className='massif-monitor-container' style={{ backgroundImage: `url(${BackgroundImage})` }}>
            <div className="title-area"><div className='return-area' onClick={() => navigate(-1)}></div></div>
            <div className="content">
                <div className="left"
                    ref={LeftRef}
                    style={{
                        backgroundImage: `url(${getBackgroundImage()})`,
                        backgroundColor: currentMode === MODE.VIDEO ? 'rgba(0, 0, 0, 0.30)' : 'transparent',
                        backdropFilter: currentMode === MODE.VIDEO ? 'blur(10px)' : 'none'
                    }}
                >
                    {/* <div className="nan-area"><NanSvg /></div> */}
                </div>
                {currentMode === MODE.MONIX && <div className="middle">
                    <div className="first-floor">
                        <Player
                            fpsDelay={800}
                            oneFpsPlay={true}
                            type={`hls`}
                            url={vedioUrlArr[0] || null}
                            isLive={true}
                            className='monitor'
                        />
                    </div>
                    <div className="second-floor">
                        <Player
                            fpsDelay={800}
                            oneFpsPlay={true}
                            type={`hls`}
                            url={vedioUrlArr[1] || null}
                            isLive={true}
                            className='monitor'
                        />
                        <Player
                            fpsDelay={800}
                            oneFpsPlay={true}
                            type={`hls`}
                            url={vedioUrlArr[2] || null}
                            isLive={true}
                            className='monitor'
                        />
                    </div>
                </div>}
                {currentMode !== MODE.MONIX && <div className="right"
                    style={{
                        backgroundImage: `url(${IndexDetailImage})`
                    }}
                ></div>}
                {currentMode === MODE.MONIX && <div className="monitor-list">
                    <div className="title">摄像头列表</div>
                    <div className="item-list">
                        {devices?.map((item, idx) => (
                            <div key={idx}
                                className="item"
                                style={{ backgroundColor: selectedDevices?.some(s => s.deviceId === item.deviceId) ? '#147FE3' : '' }}
                                onClick={() => monitorClick(item)}
                            >
                                {selectedDevices?.findIndex(s => s.deviceId === item.deviceId) > -1 && <div className='icon'>{getNumSvg(selectedDevices?.findIndex(s => s.deviceId === item.deviceId))}</div>}
                                <div className='text'>{`${item?.monitorName}`}</div>
                            </div>
                        ))}
                    </div>
                </div>}
            </div>
        </div>
    )
}
