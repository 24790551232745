import './App.less';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';
import MassifOldPanel from './routes/massif-old';
import FloorOldPanel from './routes/floor-old';
import MassifR2Panel from './routes/massif-r2';
import MassifPanel from './routes/massif';
import MassifMonitorPanel from './routes/massif-monitor';
import FloorPanel from './routes/floor';

function App() {

  const navigate = useNavigate();


  return (
    <ConfigProvider locale={zhCN}>
      <div className="base">
        <Routes>
          <Route path='/*' element={<MassifPanel />} />
          <Route path='/massif/monitor/*' element={<MassifMonitorPanel />} />
          <Route path='/floor/*' element={<FloorPanel />} />
          {/* <Route path='/massifr/*' element={<MassifR2Panel />} />
          <Route path='/massifo/*' element={<MassifOldPanel />} />
          <Route path='/floor/*' element={<FloorPanel />} /> */}
          {/* <Route path='/login/forget/*' element={<ForgetPwdPage />} />
              <Route path='/admin/*' element={<BackManageLayout />} />
              <Route path='/main/*' element={<MainLayout />} />
              <Route path='/command-center/*' element={<CommandCenter />} />
              <Route path='/check-log/:checkLogId/*' element={<CheckLogPreview />} />
              <Route path='/data-screen/*' element={<DataScreen />} /> */}
          <Route path='/*' element={<MassifPanel />} />
        </Routes>
      </div>
    </ConfigProvider>
  )
}

export default App;
