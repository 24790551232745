import Cookies from 'js-cookie'
import CryptoJS from "crypto-js";

export const GLOBAL_NAMESPACE = 'zeroasl';
export const TOKEN = `${GLOBAL_NAMESPACE}_login_ticket`;
export const USER = `${GLOBAL_NAMESPACE}_user_meta`;
export const USER_ID = `${GLOBAL_NAMESPACE}_loginid`;
export const USER_UUID = `${GLOBAL_NAMESPACE}_uuid`;
export const CAMERA_TOKEN = `${GLOBAL_NAMESPACE}_camera_token`;

class CookieStore {

    set(key: string, value: any, crossDomain: boolean = true) {
        Cookies.set(key, JSON.stringify(value), {
            domain: !!crossDomain ? document.domain.split('.').slice(-2).join('.') : window.location.host,
        });
    }

    setExpires(key: string, value: any, expires: number, crossDomain: boolean = true) {
        Cookies.set(key, JSON.stringify(value), {
            domain: !!crossDomain ? document.domain.split('.').slice(-2).join('.') : window.location.host,
            expires: expires
        });
    }

    get(key) {
        let value = Cookies.get(key);
        return JSON.parse(value || 'null') || null;
    }

    setToken(token: string) {
        Cookies.set(TOKEN, token, {
            domain: document.domain.split('.').slice(-2).join('.'),
            expires: 60
        })
    }

    getToken() {
        return Cookies.get(TOKEN);
    }

    setLoginId(id: number) {
        Cookies.set(USER_ID, `${id}`, {
            domain: document.domain.split('.').slice(-2).join('.'),
            expires: 60
        })
    }

    getLoginId() {
        return +Cookies.get(USER_ID) || null;
    }

    setUUID(uuid: string) {
        Cookies.set(USER_UUID, `${uuid}`, {
            domain: document.domain.split('.').slice(-2).join('.'),
            expires: 60
        })
    }

    getUUID() {
        return Cookies.get(USER_UUID) || null;
    }

   
    setCameraToken(deviceId: number, cameraToken: String) {
        Cookies.set(`${GLOBAL_NAMESPACE}_camera_token_${deviceId}`, cameraToken, {
            domain: document.domain.split('.').slice(-2).join('.'),
        });
    }

    getCameraToken(deviceId) {
        return Cookies.get(`${GLOBAL_NAMESPACE}_camera_token_${deviceId}`) || null;
    }

    clear() {
        let domain = document.domain.split('.').slice(-2).join('.');
        Cookies.remove(TOKEN, {
            domain: domain,
        });
        Cookies.remove(USER_ID, {
            domain: domain,
        });
        Cookies.remove(USER, {
            domain: domain,
        });
        Cookies.remove(USER_UUID, {
            domain: domain,
        });
        // Cookies.remove(CAMERA_TOKENS, {
        //     domain: domain,
        // });
        var cookies = Cookies.get();
        for (var cookieName in cookies) {
            if (cookieName.indexOf(CAMERA_TOKEN) === 0) {
                Cookies.remove(cookieName);
            }
        }
    }
}

export default new CookieStore();