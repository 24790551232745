import { useEffect, useRef, useState } from 'react';
import './index.less'
import { Carousel, Image } from 'antd';
import { ReactComponent as BigSvg } from '@/icons/big.svg'
import { useNavigate } from 'react-router-dom';
import { getDevicesByGroupId, getMonitoringVideo } from '@/api/video';
import React from 'react';
import { Player } from 'lm-player';
import { ReactComponent as NanSvg } from '@/icons/nan.svg'
import { ReactComponent as CopySvg } from '@/icons/copy.svg'

export const MODE = {
    MAP: 1,
    STREET: 2,
}

export default function MassifPanel() {

    const BackgroundImage = process.env.PUBLIC_URL + '/media/background2.png';
    const IndexDetailImage = process.env.PUBLIC_URL + '/media/index-detail.png';
    const LeftFloorImage = process.env.PUBLIC_URL + '/media/left-floor.png';
    const LeftFloorHoverImage = process.env.PUBLIC_URL + '/media/left-floor-hover.png';
    const Street1Image = process.env.PUBLIC_URL + '/media/street1.jpg';
    const Street2Image = process.env.PUBLIC_URL + '/media/street2.jpg';
    const Street3Image = process.env.PUBLIC_URL + '/media/street3.jpg';
    const Street4Image = process.env.PUBLIC_URL + '/media/street4.jpg';
    const Street5Image = process.env.PUBLIC_URL + '/media/street5.jpg';
    const MapImage = process.env.PUBLIC_URL + '/media/map.png';
    const BridgeVideo = process.env.PUBLIC_URL + '/media/470.mp4';

    const imageStyle = {
        position: 'absolute',
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    };

    const navigate = useNavigate();
    const LeftRef = useRef(null);
    const [showSubBorder, setShowSubBorder] = useState(false);
    const [devices, setDevices] = useState([]);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [vedioUrl, setVedioUrl] = useState('');
    const [timeStr, setTimeStr] = useState('');
    const [currentMode, setCurrentMode] = useState<number>(MODE.MAP);
    const [width, setWidth] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);

    useEffect(() => {

    }, [])

    useEffect(() => {
        getDevicesByGroupId().then(res1 => {
            let devices = (res1 as any).result || [];
            setDevices(devices);
            let deviceId;
            if (devices?.length > 0) {
                let device = devices[1];
                deviceId = device.deviceId;
                setSelectedDevices([device]);
            }
            return deviceId;
        }).then(deviceId => {
            if (!deviceId) return;
            getMonitoringVideo(deviceId).then(res2 => {
                let vedioUrl = (res2 as any).result.videoUrl;
                setVedioUrl(vedioUrl);
            })
        })
    }, [])

    useEffect(() => {
        if (!!LeftRef) {
            let w = LeftRef.current.clientWidth;
            let h = LeftRef.current.clientHeight;
            setWidth(w);
            setHeight(h);
        }
    }, [LeftRef])

    const handleResize = () => {
        if (!!LeftRef) {
            let w = LeftRef.current.clientWidth;
            let h = LeftRef.current.clientHeight;
            setWidth(w);
            setHeight(h);
        }
    };

    useEffect(() => {
        // 添加窗口大小变化事件的监听器
        window.addEventListener('resize', handleResize);

        // 在组件卸载时移除事件监听器
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='base2-container'
            style={{ backgroundImage: `url(${BackgroundImage})` }}
        >
            <div className="title-area"></div>
            <div className="content">
                <div className={showSubBorder ? "left-hover" : "left"}
                    ref={LeftRef}
                    style={{
                        // backgroundImage: `url(${showSubBorder ? LeftFloorHoverImage : LeftFloorImage})`,

                        cursor: showSubBorder ? 'pointer' : 'default'
                    }}
                >
                    <div className='hover-area'
                        onMouseEnter={() => setShowSubBorder(true)}
                        onMouseLeave={() => setShowSubBorder(false)}
                        onClick={() => navigate(`/floor`)}
                    ></div>
                    {/* <div className="btn-group-area"></div> */}
                    {/* <div className="nan-area"><NanSvg /></div> */}
                </div>
                <div className="middle">
                    <div className='item'>
                        <div className='item-titles'>
                            <div className='title'>
                                航拍视频
                            </div>
                            {/* <div className='icon'><BigSvg /></div> */}
                        </div>
                        <div className='lunbo'>
                            <video
                                controls
                                width={385}
                                height={!!height ? (height / 3) - 70 : 100}
                            >
                                <source src={BridgeVideo} type="video/mp4" />
                                Your browser does not support the video.
                            </video>
                        </div>
                    </div>
                    <div className='item'>
                        <div className='item-titles'>
                            <div
                                className='title'
                                style={{ color: currentMode === MODE.MAP ? '#FFF' : 'rgba(255, 255, 255, 0.40)' }}
                                onClick={() => setCurrentMode(MODE.MAP)}
                            >地图</div>
                            <div
                                className='title'
                                style={{ color: currentMode === MODE.STREET ? '#FFF' : 'rgba(255, 255, 255, 0.40)' }}
                                onClick={() => setCurrentMode(MODE.STREET)}
                            >街景</div>
                            <div className='icon' style={{ marginBottom: 4 }}><CopySvg /></div>
                            <div className='icon' style={{ marginLeft: 16 }}><BigSvg /></div>
                        </div>
                        {currentMode === MODE.STREET && <>

                            <div className='lunbo'>
                                <Carousel autoplay>
                                    <Image
                                        src={Street1Image}
                                        preview={false}
                                        height={!!height ? (height / 3) - 70 : 100}
                                        width={385}
                                    />
                                    <Image
                                        src={Street2Image}
                                        preview={false}
                                        height={!!height ? (height / 3) - 70 : 100}
                                        width={385}
                                    />
                                    <Image
                                        src={Street3Image}
                                        preview={false}
                                        height={!!height ? (height / 3) - 70 : 100}
                                        width={385}
                                    />
                                    <Image
                                        src={Street4Image}
                                        preview={false}
                                        height={!!height ? (height / 3) - 70 : 100}
                                        width={385}
                                    />
                                    <Image
                                        src={Street5Image}
                                        preview={false}
                                        height={!!height ? (height / 3) - 70 : 100}
                                        width={385}
                                    />
                                </Carousel>
                            </div>
                        </>}
                        {currentMode === MODE.MAP && <>
                            <div className='lunbo'>
                                <Image
                                    src={MapImage}
                                    preview={false}
                                    height={!!height ? (height / 3) - 70 : 100}
                                    width={385}
                                />
                            </div>
                        </>}
                    </div>
                    <div className='item'>
                        <div className='item-titles'>
                            <div className='title'>
                                监控
                            </div>
                            <div className='icon' onClick={() => navigate(`/massif/monitor`)}><BigSvg /></div>
                        </div>

                        <div className='lunbo' style={{ height: !!height ? (height / 3) - 70 : 100 }}>
                            {vedioUrl && <Player
                                fpsDelay={800}
                                oneFpsPlay={true}
                                type={`hls`}
                                url={vedioUrl}
                                isLive={true}
                            />}

                        </div>
                    </div>
                </div>
                <div className="right"
                    style={{
                        backgroundImage: `url(${IndexDetailImage})`
                    }}
                ></div>
            </div>
        </div>
    )
}
