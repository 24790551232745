import cookieStore from '@/store/cookie-store';
import axios, { AxiosResponse } from 'axios';

export interface StdResponse<T = any> extends AxiosResponse<T> {
    message: string
}

// 状态码定义
// const codeMessage = {
//     200: 'ok',
//     400: '请求数据错误',
//     401: '用户没有权限',
//     404: '请求的资源不存在',
//     500: '服务器异常',
//     503: '服务器过载或维护',
//     504: '网关超时',
// }

axios.defaults.timeout = 60000;
axios.defaults.baseURL = '/api';

axios.interceptors.request.use(
    config => {
        // 从本地Cookie获取token
        // let token = cookieStore.getToken() || '';
        // config.headers.token = token;
        config.headers[`X-Access-Token`] = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VybmFtZSI6InBiX2FkbWluIiwic3ViIjoiZGVtbyIsImlhdCI6MTcxMDM4MDQxMCwibmJmIjoxNzEwMzgwNDEwLCJleHAiOjk5OTk5OTk5OTl9.drIiwWfGpnY6uG1jj4AH-3yFWa9uXOkbfA44fsCRGnc';
        return config;
    },
    err => {
        console.log(err);
    }
)
axios.interceptors.response.use(
    response => {
        if (response.headers['content-type']?.indexOf('application/json') >= 0) {
            return response.data;
        }
        return response;
    },
    (err) => {
        const error = err.response;
        if (!error) {
            return Promise.reject("发生其他异常");
        }
        if (error.statue === 401) {
            // 未授权登录
            // appStore.clear();
            // store.userStore.setIsLogin(false);
        }
        return Promise.reject(error);
    }
)

export const apiFoxAxios = axios.create({
    // https://www.apifox.cn/help/app/mock/#%E4%BA%8C%E3%80%81mock-%E8%AF%B7%E6%B1%82-url
    // baseURL: 'http://localhost:4523/m1/1886233-0-default', // 本地mock，有apifox客户端可使用
    baseURL: 'https://mock.apifox.cn/m1/1886233-0-default', // 云端mock，不下载apifox也可使用
    timeout: 60000,
})

apiFoxAxios.interceptors.request.use(
    config => {
        // 从本地Cookie获取token
        let token = cookieStore.getToken() || '';
        config.headers.token = token;
        return config;
    },
    err => {
        console.log(err);
    }
)
apiFoxAxios.interceptors.response.use(
    response => {
        if (response.headers['content-type']?.indexOf('application/json') >= 0) {
            return response.data;
        }
        return response;
    },
    (err) => {
        const error = err.response;
        if (!error) {
            return Promise.reject("发生其他异常");
        }
        if (error.statue === 401) {
            // 未授权登录
            // appStore.clear();
            // store.userStore.setIsLogin(false);
        }
        return Promise.reject(error);
    }
)

export default axios;


