import { useEffect, useRef, useState } from 'react';
import './index.less'
import { useNavigate } from 'react-router-dom';
import { getDevicesByGroupId, getMonitoringVideo } from '@/api/video';
import { Player } from 'lm-player';
import { ReactComponent as NanSvg } from '@/icons/nan.svg'
import { ReactComponent as RedSvg } from '@/icons/op/red.svg'
import { ReactComponent as OrangeSvg } from '@/icons/op/orange.svg'
import { ReactComponent as GreenSvg } from '@/icons/op/green.svg'
import { ReactComponent as PatrolSvg } from '@/icons/ma/patrol.svg'
import { ReactComponent as FireSvg } from '@/icons/ma/fire.svg'
import { ReactComponent as DeviceSvg } from '@/icons/ma/device.svg'
import { ReactComponent as AnnoSvg } from '@/icons/ma/anno.svg'
import { ReactComponent as JifangSvg } from '@/icons/ma/jifang.svg'
import { ReactComponent as BengfangSvg } from '@/icons/ma/bengfang.svg'
import { ReactComponent as XfsSvg } from '@/icons/ma/xfs.svg'
import { ReactComponent as BigSvg } from '@/icons/big.svg'
import { ReactComponent as OneSvg } from '@/icons/number/one.svg'
import { ReactComponent as TwoSvg } from '@/icons/number/two.svg'
import { ReactComponent as ThreeSvg } from '@/icons/number/three.svg'
import axios from 'axios';
import classnames from 'classnames'


export const MODE = {
    OPERATIONS: 1,
    MANAGE: 2,
    MONIX: 3,
    VR: 4,
}

export const MANAGE = {
    PATROL: 1,
    FIRE: 2,
    DEVICE: 3,
}

export default function FloorPanel() {

    const BackgroundImage = process.env.PUBLIC_URL + '/media/background2.png';
    const FloorImage = process.env.PUBLIC_URL + '/media/floor.png';
    const FloorHoverImage = process.env.PUBLIC_URL + '/media/floor-hover.png';
    const PatrolImage = process.env.PUBLIC_URL + '/media/patrol.png';
    const FireImage = process.env.PUBLIC_URL + '/media/fire.png';
    const FireHoverImage = process.env.PUBLIC_URL + '/media/fire-hover.png';
    const DeviceImage = process.env.PUBLIC_URL + '/media/device.png';
    const DeviceDetailImage = process.env.PUBLIC_URL + '/media/device-detail.png';
    const FireDetailImage = process.env.PUBLIC_URL + '/media/fire-detail.png';
    const FireListImage = process.env.PUBLIC_URL + '/media/fire-list.png';
    const PatrolDetailImage = process.env.PUBLIC_URL + '/media/patrol-detail.png';
    const WarningImage = process.env.PUBLIC_URL + '/media/warning.png';
    const FloorDetailImage = process.env.PUBLIC_URL + '/media/floor-detail.png';
    const FloorMonitorImage = process.env.PUBLIC_URL + '/media/floor-monitor.png';

    const navigate = useNavigate();
    const LeftRef = useRef(null);
    const [showSubBorder, setShowSubBorder] = useState(false);
    const [devices, setDevices] = useState([]);
    const [selectedDevices, setSelectedDevices] = useState([]);
    const [vedioUrl, setVedioUrl] = useState('');
    const [vedioUrlArr, setVedioUrlArr] = useState<string[]>([]);
    const [currentMode, setCurrentMode] = useState<number>(MODE.OPERATIONS);
    const [manageMode, setManageMode] = useState<number>(null);
    const [showFireDetail, setShowFireDetail] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);

    useEffect(() => {
        if (currentMode != MODE.MANAGE) {
            setManageMode(null);
        } else {
            setManageMode(MANAGE.PATROL);
        }
        setShowFireDetail(false);
    }, [currentMode])

    useEffect(() => {
        setShowFireDetail(false);
    }, [manageMode])

    const getBackgroundImage = () => {
        if (currentMode === MODE.OPERATIONS && showSubBorder) {
            return FloorHoverImage;
        }
        if (currentMode === MODE.OPERATIONS && !showSubBorder) {
            return FloorHoverImage;
        }
        if (currentMode === MODE.MONIX) {
            return FloorMonitorImage;
        }
        if (currentMode === MODE.MANAGE) {
            if (manageMode == null) {
                return FloorImage;
            } else if (manageMode === MANAGE.PATROL) {
                return PatrolImage;
            } else if (manageMode === MANAGE.FIRE) {
                if (showSubBorder) {
                    return FireHoverImage;
                } else {
                    return FireImage;
                }
            } else if (manageMode === MANAGE.DEVICE) {
                return DeviceImage;
            }
        }
        return FloorImage;
    };

    const getRightImage = () => {
        if (currentMode === MODE.OPERATIONS) {
            return FloorDetailImage;
        }
        if (currentMode === MODE.MANAGE) {
            if (manageMode == null) {
                return FloorDetailImage;
            } else if (manageMode === MANAGE.PATROL) {
                return PatrolDetailImage;
            } else if (manageMode === MANAGE.FIRE) {
                if (showFireDetail) {
                    return FireDetailImage;
                }
                if (!showSubBorder) {
                    return FireListImage;
                }
                if (showSubBorder) {
                    return FireListImage;
                }
            } else if (manageMode === MANAGE.DEVICE) {
                return DeviceDetailImage;
            }
        }
        if (currentMode === MODE.VR) {
            return FloorDetailImage;
        }
        return WarningImage;
    };

    const getNumSvg = (number) => {
        if (number === 0) {
            return <OneSvg />
        } else if (number === 1) {
            return <TwoSvg />
        } else if (number === 2) {
            return <ThreeSvg />
        }
        return null
    }

    useEffect(() => {
        if (!!LeftRef) {
            let w = LeftRef.current.clientWidth;
            let h = LeftRef.current.clientHeight;
            setWidth(w);
            setHeight(h);
        }
    }, [LeftRef])

    const handleResize = () => {
        if (!!LeftRef) {
            let w = LeftRef.current.clientWidth;
            let h = LeftRef.current.clientHeight;
            setWidth(w);
            setHeight(h);
        }
    };

    useEffect(() => {
        // 添加窗口大小变化事件的监听器
        window.addEventListener('resize', handleResize);

        // 在组件卸载时移除事件监听器
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        getDevicesByGroupId().then(res1 => {
            let devices = (res1 as any).result || [];
            setDevices(devices);
            return devices;
        }).then(devices => {
            if (!devices) return;
            let deviceArr = [];
            let axiosArr = [];
            // for (let d of devices) {
            for (let i = 0; i < devices?.length; i++) {
                if (i === 0) continue;
                let d = devices[i];
                let deviceId = d.deviceId;
                if (axiosArr?.length < 2) {
                    axiosArr.push(getMonitoringVideo(deviceId));
                    deviceArr.push(d);
                } else {
                    break;
                }
            }
            setSelectedDevices(deviceArr);
            axios.all([...axiosArr]).then(res => {
                let vedioUrlArr = [];
                for (let r of res) {
                    let vedioUrl = (r as any).result.videoUrl;
                    vedioUrlArr.push(vedioUrl);
                }
                setVedioUrlArr(vedioUrlArr);
            })
        })
    }, [])


    const monitorClick = (device) => {
        let newSrr = [...selectedDevices];
        newSrr.unshift(device);
        newSrr.pop();
        setSelectedDevices(newSrr);
        getMonitoringVideo(device.deviceId).then(res => {
            let videoUrl = (res as any).result.videoUrl;
            let newUrls = [...vedioUrlArr];
            newUrls.unshift(videoUrl);
            newUrls.pop();
            setVedioUrlArr(newUrls);
        })
    }

    return (
        <div className='base-container' style={{ backgroundImage: `url(${BackgroundImage})` }}>
            <div className="title-area">
                <div className='return-area' onClick={() => {
                    if (showFireDetail) {
                        setShowFireDetail(false);
                    } else {
                        navigate(-1);
                    }
                }}></div>
            </div>
            <div className="content">
                <div className={classnames({
                    'left': true,
                    'op-left': currentMode === MODE.OPERATIONS,
                    'monix-left': currentMode === MODE.MONIX,
                    'patrol-left': currentMode === MODE.MANAGE && manageMode === MANAGE.PATROL,
                    'device-left': currentMode === MODE.MANAGE && manageMode === MANAGE.DEVICE,
                    'fire-left': currentMode === MODE.MANAGE && manageMode === MANAGE.FIRE && !showSubBorder,
                    'fire-hover-left': ((currentMode === MODE.MANAGE && manageMode === MANAGE.FIRE && showSubBorder) || (currentMode === MODE.MANAGE && manageMode === MANAGE.FIRE && showFireDetail)),
                    'floor-left': !currentMode,
                })}
                    ref={LeftRef}
                    style={{
                        // backgroundImage: `url(${getBackgroundImage()})`,
                        backgroundColor: currentMode === MODE.VR ? 'rgba(0, 0, 0, 0.30)' : 'transparent',
                        backdropFilter: currentMode === MODE.VR ? 'blur(10px)' : 'none'
                    }}

                >
                    <div className='hover-area'
                        onMouseEnter={() => setShowSubBorder(true)}
                        onMouseLeave={() => setShowSubBorder(false)}
                        onClick={() => setShowFireDetail(true)}
                    ></div>
                    <div className="btn-group-area">
                        <div className="btn"
                            style={{ backgroundColor: currentMode === MODE.OPERATIONS ? '#147FE3' : '' }}
                            onClick={() => setCurrentMode(pre => pre === MODE.OPERATIONS ? null : MODE.OPERATIONS)}>运营</div>
                        <div className="btn"
                            style={{ backgroundColor: currentMode === MODE.MANAGE ? '#147FE3' : '' }}
                            onClick={() => { setCurrentMode(pre => pre === MODE.MANAGE ? null : MODE.MANAGE); setManageMode(MANAGE.PATROL) }}>管理</div>
                        <div className="btn"
                            style={{ backgroundColor: currentMode === MODE.MONIX ? '#147FE3' : '' }}
                            onClick={() => setCurrentMode(pre => pre === MODE.MONIX ? null : MODE.MONIX)}>监控</div>
                        <div className="btn"
                            style={{ backgroundColor: currentMode === MODE.VR ? '#147FE3' : '' }}
                            onClick={() => setCurrentMode(pre => pre === MODE.VR ? null : MODE.VR)}>VR</div>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            position: 'absolute',
                            right: '12px',
                            top: '14px',
                        }}><BigSvg /></div>
                    </div>
                    {currentMode === MODE.VR && <div className="video-area">
                        <iframe
                            src="https://jk.railshj.com/panorama/?point=xmq"
                            title="External Page"
                            width="100%"
                            height="100%"
                            frameBorder="0"
                        />
                    </div>}
                    {currentMode === MODE.OPERATIONS && <div className='op-area'>
                        <div className='op-item'>
                            <div className='icon'><GreenSvg /></div>可租</div>
                        <div className='op-item'>
                            <div className='icon'><OrangeSvg /></div>已租</div>
                        <div className='op-item'>
                            <div className='icon'><RedSvg /></div>临期</div>
                    </div>}
                    {currentMode === MODE.MANAGE && <div className='ma-area'>
                        <div className='ma-item'
                            style={{ backgroundColor: manageMode === MANAGE.PATROL ? '#01C000' : '' }}
                            onClick={() => setManageMode(pre => pre === MANAGE.PATROL ? null : MANAGE.PATROL)}
                        >
                            <div className='icon'><PatrolSvg /></div>
                            巡更系统
                        </div>
                        <div className='ma-item'
                            style={{ backgroundColor: manageMode === MANAGE.FIRE ? '#BF0A06' : '' }}
                            onClick={() => setManageMode(pre => pre === MANAGE.FIRE ? null : MANAGE.FIRE)}
                        >
                            <div className='icon'><FireSvg /></div>
                            消防设施
                        </div>
                        <div className='ma-item' style={{ backgroundColor: manageMode === MANAGE.DEVICE ? '#147FE3' : '' }}
                            onClick={() => setManageMode(pre => pre === MANAGE.DEVICE ? null : MANAGE.DEVICE)}
                        >
                            <div className='icon'><DeviceSvg /></div>
                            特种设备
                        </div>
                        <div className='ma-item'>
                            <div className='icon'><JifangSvg /></div>
                            机房
                        </div>
                        <div className='ma-item'>
                            <div className='icon'><BengfangSvg /></div>
                            泵房
                        </div> <div className='ma-item' >
                            <div className='icon'><XfsSvg /></div>
                            消控室
                        </div>
                    </div>}
                    {currentMode === MODE.MANAGE && <div className='ma-area2'>

                        <div className='ma-item' >
                            <div className='icon'><AnnoSvg /></div>
                            标注拍照
                        </div>
                    </div>}
                    {/* {currentMode === MODE.MONIX && <div className="nan-area"><NanSvg /></div>} */}
                    {/* {currentMode === MODE.MANAGE && manageMode === MANAGE.FIRE && <div className='fire-d-area' onClick={() => setShowFireDetail(true)}></div>} */}
                </div>
                {currentMode === MODE.MONIX && <div className="middle">
                    <div className="first-floor">
                        <Player
                            fpsDelay={800}
                            oneFpsPlay={true}
                            type={`hls`}
                            url={vedioUrlArr[0] || null}
                            isLive={true}
                            className='monitor'
                        />
                    </div>
                    <div className="second-floor">
                        <Player
                            fpsDelay={800}
                            oneFpsPlay={true}
                            type={`hls`}
                            url={vedioUrlArr[1] || null}
                            isLive={true}
                            className='monitor'
                        />
                    </div>
                </div>}
                {currentMode !== MODE.MONIX && <div className={classnames({
                    "right": true,
                    'op-right': currentMode === MODE.OPERATIONS,
                    'monix-right': currentMode === MODE.MONIX,
                    'patrol-right': currentMode === MODE.MANAGE && manageMode === MANAGE.PATROL,
                    'device-right': currentMode === MODE.MANAGE && manageMode === MANAGE.DEVICE,
                    'fire-right': ((currentMode === MODE.MANAGE && manageMode === MANAGE.FIRE && !showFireDetail && !showSubBorder) || currentMode === MODE.MANAGE && manageMode === MANAGE.FIRE && !showFireDetail && showSubBorder),
                    'fire-detail-right': currentMode === MODE.MANAGE && manageMode === MANAGE.FIRE && showFireDetail,
                    'warning-right': ((!currentMode) || (currentMode === MODE.MANAGE && !manageMode)),
                    'vr-right': currentMode === MODE.VR,
                })}
                // style={{
                //     backgroundImage: `url(${getRightImage()})`
                // }}
                >
                </div>}
                {currentMode === MODE.MONIX && <div className="monitor-list">
                    <div className="title">摄像头列表</div>
                    <div className="item-list">
                        {devices?.map((item, idx) => (
                            <div key={idx}
                                className="item"
                                style={{ backgroundColor: selectedDevices?.some(s => s.deviceId === item.deviceId) ? '#147FE3' : '' }}
                                onClick={() => monitorClick(item)}
                            >
                                {selectedDevices?.findIndex(s => s.deviceId === item.deviceId) > -1 && <div className='icon'>{getNumSvg(selectedDevices?.findIndex(s => s.deviceId === item.deviceId))}</div>}
                                <div className='text'>{`${item?.monitorName}`}</div>

                            </div>
                        ))}
                    </div>
                </div>}
            </div>
        </div>
    )
}
